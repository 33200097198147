import './casesList.css';
import React from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import CaseCard from '../caseCard/CaseCard';

const CasesList = () => {
  const theme = React.useContext(ThemeContext);

  // временные переменные до готовности бэка
  const caseTitle = {
    title1: 'Активный начальник',
    title2: 'Обиженный подчиненный',
    title3: 'Автобизнес',
    title4: 'Амбициозная бизнес-вумен',
    title5: 'Кто виноват',
    title6: 'Аттестация для всех',
    title7: 'Домик в деревне',
    title8: 'Антиквариат'
  };

  const caseText = {
    text1: 'Молодой руководитель работает Начальником отдела маркетинга в производственной компании. Он профессионал, в прошлом году получил звание «Сотрудник года», пользуется доверием собственника. Полгода назад в компанию пришёл новый Директор департамента маркетинга и рекламы и задал напряжённый темп работы. Сначала выросли продажи. Затем выросло недовольство сотрудников внеурочной работой. Ждать череду увольнений?',
    text2: 'В компании конфликт между зам.генерального и региональным директорами. Зам.генерального издаёт приказ о строгой иерархии при подаче информации внутри компании, и теперь все предложения регионального директора представляет генеральному от своего имени. Региональный директор хочет упрочить своё положение или уйти. Что лучше?',
    text3: 'Два учредителя владеют бизнесом по сдаче в аренду автомобилей. Когда наступили трудные времена, появились разногласия по поводу вклада каждого. Один из них предлагает мирный раздел бизнеса.',
    text4: 'На работу взяли нового коммерческого директора, но заявления о приёму на работе по форме он так и не пишет, только какие-то лирические опусы… Почему? И что делать?',
    text5: 'Ресторану подарили антикварное стекло для оформления интерьера. Даритель отправил подарок через транспортную компанию на условиях, что транспортировку оплачивает получатель. По дороге стекло разбилось. Транспортная компания не считает себя виноватой, т.к. приняла груз в упаковке отправителя. И требует от получателя 10 тыс. ₽ за транспортировку. Как быть?',
    text6: 'В подразделении вводят процедуру аттестации, а самый опытный менеджер отказывается в ней участвовать. Почему и что делать?',
    text7: 'Два брата получили в наследство участок земли с деревенским домом. Хотели его продать, но руки не доходили. Неожиданно к деревне провели дорогу и газ. Дом значительно подорожал. И тут интересы братьев разошлись. Смогут ли они договориться?',
    text8: 'В торговую компанию выходит на работу новый торговый представитель Наталья. Супервайзер передаёт Наталье часть точек, ранее закреплённых за другим торговым представителем, Антоном. Среди передаваемых клиентов один — с просроченной задолженностью. Наталья выезжает к клиенту, но он бывает только поздно вечером, и Наталья просит Антона съездить и забрать деньги. Антон соглашается. Через некоторое время Антон увольняется. Через месяц увольняется Наталья, но при расчёте всплывает проблемный клиент и просроченная задолженность. Кто виноват и что делать?'
  }
  return (
    <>
      <div className='caseslist'>
        <CaseCard title={caseTitle.title1} text={caseText.text1} />
        <CaseCard title={caseTitle.title2} text={caseText.text2} />
        <CaseCard title={caseTitle.title3} text={caseText.text3} />
        <CaseCard title={caseTitle.title4} text={caseText.text4} />
        <CaseCard title={caseTitle.title5} text={caseText.text5} />
        <CaseCard title={caseTitle.title6} text={caseText.text6} />
        <CaseCard title={caseTitle.title7} text={caseText.text7} />
        <CaseCard title={caseTitle.title8} text={caseText.text8} />
      </div>
      <button className={`caseslist__button ${theme === 'day' ? '' : 'caseslist__button_theme_dark'}`} type='button'>Смотреть еще</button>
    </>
  )
};

export default CasesList;