import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../contexts/ThemeContext';
import './casePage.css';

const CasePage = ({ title }) => {
  const theme = React.useContext(ThemeContext);

  return (
    <section className="casepage">
      <nav className="casepage__crumbs">
        <ul>
          <li className="casepage__crumb">
            <Link
              to="/cases"
              className={`casepage__crumb-link ${
                theme === 'day' ? '' : 'casepage__crumb-link_dark'
              }`}
            >
              База кейсов
            </Link>
          </li>
          <li className="casepage__crumb">Активный начальник</li>
        </ul>
      </nav>
      <h2 className="casepage__title">Активный начальник</h2>
      <div className="casepage__container">
        <div className="casepage__tags">
          <p
            className={`casepage__tag-clock ${
              theme === 'day' ? '' : 'casepage__tag-clock_dark'
            }`}
          >
            4 min
          </p>
          <div className="casepage__tag">Трудоустройство</div>
        </div>
        <ul className="casepage__articles">
          <li className="casepage__article">
            <h3 className="casepage__article-title">Ситуация</h3>
            <p className="casepage__article-text">
              Молодой руководитель работает Начальником отдела маркетинга (НОМ)
              в производственной компании. Он профессионал, в прошлом году
              получил звание «Сотрудник года», пользуется доверием собственника.
              Полгода назад в компанию пришёл новый Директор департамента
              маркетинга и рекламы, прямой руководитель НОМ. Он — человек
              амбициозный, творческий. За прошедшие месяцы Директор департамента
              сумел запустить несколько сильных маркетинговых акций. Продажи
              компании в этот период показали рост выше рынка. Собственник
              доволен активностью Директора департамента. НОМ с большим
              интересом работал над проектами, предложенными Директором
              департамента в первые месяцы после его прихода в компанию.
              Но обнаружилась и обратная сторона этой активности. Директор
              департамента имеет привычку звонить сотрудникам в неурочное время,
              поздним вечером обсуждать по мессенджеру идеи, назначать
              видеоконференцию в выходной день. Внеплановые задачи изменили
              режим работы и у подчинённых НОМа. Из-за напряжённого графика
              планирует увольняться Ключевой специалист отдела маркетинга.
              Последней каплей стала ситуация, когда НОМ забыл
              об онлайн-совещании в воскресенье в 10 утра, собираясь на давно
              запланированную семейную встречу. Когда в последний момент
              он вспомнил о встрече и попросил перенести совещание, Директор
              департамента сказал, что с таким стремлением к работе
              НОМ в команде скоро станет лишним.
            </p>
          </li>
          <li className="casepage__article">
            <h3 className="casepage__article-title">Роли и интересы</h3>
            <p className="casepage__article-text">
              Директор департамента маркетинга и рекламы — хочет вывести
              компанию в лидеры рынка. Если сотрудник хочет роста и развития,
              то должен быть благодарен, что ему звонят и обсуждают
              с ним задачи. А если это напрягает — пусть увольняется. Начальник
              отдела маркетинга — устал от этой гонки, но любит свою работу,
              хочет остаться работать в компании, считает, что должен быть
              баланс между работой и личной жизнью. Ключевой специалист отдела
              маркетинга — готов отстаивать своё право самостоятельно
              планировать своё̈ нерабочее время, в случае отказа готов уволиться
              и перейти на фриланс. Опыт частных успешных проектов уже имеется.
            </p>
          </li>
        </ul>
        <p className="casepage__back">
          <Link
            to="/cases"
            className={`casepage__back-link ${
              theme === 'day' ? '' : 'casepage__back-link_dark'
            }`}
          >
            Назад к кейсам
          </Link>
        </p>
      </div>
    </section>
  );
};

export default CasePage;
